import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Marquee from 'react-fast-marquee';

import { Container, makeStyles, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  slide: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 1rem',
  },
}));

const CustomSlide = ({ logo }) => {
  const classes = useStyles();
  return logo.link ? (
    <div className={classes.slide}>
      <a href={logo.link} target='_blank' rel='noreferrer'>
        <GatsbyImage
          alt='logo'
          image={logo?.logo.asset.gatsbyImageData}
          imgStyle={{ objectFit: 'contain' }}
        />
      </a>
    </div>
  ) : (
    <div className={classes.slide}>
      <GatsbyImage
        alt='logo'
        image={logo.firmLogo.asset.gatsbyImageData}
        imgStyle={{ objectFit: 'contain' }}
      />
    </div>
  );
};
export const ProductLogos = ({ logos }) => {
  const classes = useStyles();
  const lg = useMediaQuery('(max-width: 1280px)');

  return (
    <div style={{ padding: '4rem 0' }}>
      {lg ? (
        <Marquee pauseOnHover gradient>
          {logos.map((logo, index) => (
            <CustomSlide logo={logo} key={index} />
          ))}
        </Marquee>
      ) : (
        <Container>
          <Marquee pauseOnHover gradient>
            {logos.map((logo, index) => (
              <CustomSlide logo={logo} key={index} />
            ))}
          </Marquee>
        </Container>
      )}
    </div>
  );
};
