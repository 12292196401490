import React from 'react';
import PortableText from 'react-portable-text';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Grid, Container, Typography, makeStyles } from '@material-ui/core';
import { IntroCard } from './IntroCard';

const useStyles = makeStyles((theme) => ({
  intro: {
    // marginTop: '-1rem',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      // marginTop: '-3rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
}));

export const Intro = ({ header, cards, awards }) => {
  const classes = useStyles();
  return (
    <Container>
      <Grid container direction='row' justifyContent='center'>
        <Grid item xs={12}>
          <PortableText
            content={header}
            className={classes.intro}
            serializers={{
              h2: ({ children }) => (
                <Typography
                  variant='h2'
                  color='primary'
                  style={{
                    fontWeight: 700,
                    paddingBottom: '4rem',
                    textAlign: 'center',
                  }}
                >
                  {children}
                </Typography>
              ),
              strong: ({ children }) => (
                <span style={{ color: '#2f7fc1' }}>{children}</span>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent='space-evenly'
        alignItems='center'
        style={{ paddingBottom: '4rem' }}
      >
        {awards.map((award, index) => (
          <GatsbyImage key={index} image={award.asset?.gatsbyImageData} />
        ))}
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={4}
      >
        {cards.map((card, index) => (
          <IntroCard key={index} card={card} />
        ))}
      </Grid>
    </Container>
  );
};
